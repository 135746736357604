import { computed, Ref } from "vue";

import { usePermissions } from "@/composables/usePermissions";
import { getProductLine, PRODUCT_LINES, ProductLine } from "@/lib/productLine";

interface UseInAppsPermissionsOptions {
  productLineId: Ref<number>;
}

export type UseInAppPermissions = typeof useInAppPermissions;

export interface InAppPermissions {
  hasAddInAppPermission: boolean;
  hasEditInAppPermission: boolean;
  hasDeleteInAppPermission: boolean;
  hasAddImagePermission: boolean;
  hasAddLottiePermission: boolean;
  productLinesWithAddInAppPermissions: ProductLine[];
}

export function useInAppPermissions({
  productLineId,
}: UseInAppsPermissionsOptions) {
  const { hasInAppPermission, hasBasePermission } = usePermissions();
  const productLine = computed(() => getProductLine(productLineId.value));

  const hasAddInAppPermission = computed(() =>
    hasInAppPermission("add_inapp", productLine.value)
  );

  const hasEditInAppPermission = computed(() =>
    hasInAppPermission("change_inapp", productLine.value)
  );

  const hasDeleteInAppPermission = computed(() =>
    hasInAppPermission("delete_inapp", productLine.value)
  );

  const hasAddImagePermission = computed(() => hasBasePermission("add_image"));

  const productLinesWithAddInAppPermissions = computed(() =>
    PRODUCT_LINES.filter((productLine: ProductLine) =>
      hasInAppPermission("add_inapp", productLine)
    )
  );

  return {
    permissions: computed<InAppPermissions>(() => ({
      hasAddInAppPermission: hasAddInAppPermission.value,
      hasEditInAppPermission: hasEditInAppPermission.value,
      hasDeleteInAppPermission: hasDeleteInAppPermission.value,
      hasAddImagePermission: hasAddImagePermission.value,
      hasAddLottiePermission: hasAddImagePermission.value,
      productLinesWithAddInAppPermissions:
        productLinesWithAddInAppPermissions.value,
    })),
  };
}
