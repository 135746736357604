import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'YesNoBadge',
  props: {
    value: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_q_badge = _resolveComponent("q-badge")!

  return (_openBlock(), _createBlock(_component_q_badge, {
    color: props.value ? 'primary' : 'accent'
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(props.value ? "Yes" : "No"), 1)
    ]),
    _: 1
  }, 8, ["color"]))
}
}

})