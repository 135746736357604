import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md q-gutter-y-sm" }
const _hoisted_2 = { class: "text-h6 q-py-md" }
const _hoisted_3 = { class: "title" }


export default /*@__PURE__*/_defineComponent({
  __name: 'ListFilters',
  props: {
    title: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "title-bar")
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})